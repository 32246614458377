#root {
    max-width: 1280px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    top: -100px;
}

h5{
    color: #f0ffff;
}
.Text {
    position: static;
    height: 200px;
    width: 700px;
}

@media (max-width: 768px) {
    .Text {
        width: 300px;
        position: static;
    }
}

.Container p5{
    border: 1px solid #ccc;
    border-radius: 4px;
}
.Message-Input {
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;

}

h2 {
    color: azure;
    font-size: 16px;
    font-weight: normal;
}

p {
    font-size: 50px;
    color: azure;
    margin: 19px;
}

.logo {
    height: 6em;
    padding: 1.5em;
    will-change: filter;
    transition: filter 300ms;
}

.logo:hover {
    filter: drop-shadow(0 0 2em #646cffaa);
}

.logo.react:hover {
    filter: drop-shadow(0 0 2em #61dafbaa);
}

@keyframes logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@media (prefers-reduced-motion: no-preference) {
    a:nth-of-type(2) .logo {
        animation: logo-spin infinite 20s linear;
    }
}

.card {
    padding: 2em;
}

.read-the-docs {
    color: #3f3636;
}

.ant-btn-primary {
    color: #fff;
    background-color: #000000;
    box-shadow: 0 2px 0 rgb(5 145 255 / 10%);
    margin: 20px 0;
}

.Custom-select {
    position: relative;
    width: 200px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    line-height: 40px;
    padding: 0 10px;
    cursor: pointer;
    background-color: #fff;
    user-select: none;
}

@media only screen and (max-width: 1000px) {
    .Custom-select{
        /* 重置层叠上下文为默认值 */
            isolation: auto;
            z-index: 0;
            position: static;
    }
}



.cs-message-input__content-editor {
    box-sizing: border-box;
    flex-grow: 1;
    background-color: #ffffff !important;
    font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
    outline: 0;
    border-top: 0 none;
    border-right: 0 none;
    border-bottom: 0 none;
    border-left: 0 none;
    overflow: visible;
    color: rgba(0, 0, 0, .87);
    overflow-wrap: anywhere;
    word-break: break-word;
    text-align: left;
}

.cs-message-input__content-editor-wrapper {
    background-color: #ffffff !important;
    border: none;
    border-radius: 20px !important;
    padding: 10px;
    margin-right: 10px;
    flex: 1;
    height: 100px;
    width: 50%;
}

.cs-message-input__tools {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    align-items: flex-end;
}

.cs-message-input__tools:first-child .cs-button:last-child {
    display: none;
}

.cs-message-input__tools:last-child .cs-button:last-child {
    order: 1;
    background-color: #000000;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.cs-message--outgoing .cs-message__content {
    display: none;
}

.cs-message {
    box-sizing: border-box;
    font-size: 1em;
    font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
    color: rgba(0, 0, 0, .87);
    display: flex;
    flex-direction: row;
    padding: 0;
    background-color: transparent;
    overflow: hidden;
    border-radius: 0;
    justify-content: center;
    align-items: center;
        background-color:#000000;
}

.cs-message__content {
    margin: 0 auto;
    /* 设置一个固定的宽度 */
    max-width: 500px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, .87);
    background-color: #ffffff !important;
    margin-top: 0;
    border-radius: 0.7em 0.7em 0.7em 0.7em;
    white-space: pre-wrap;
    overflow-wrap: anywhere;
    word-break: break-word;
    font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: .91em;
    font-feature-settings: normal;
    font-variant: normal;
    text-align: left;
}

.cs-message:not(:only-child) {
    margin: 0.2em 0 0 0;
    justify-content: center !important;
    align-items: center !important;
}

element.style{
    overscroll-behavior-y: none;
        overflow-anchor: auto;
        max-height: 300px !important;
        touch-action: none;
}

.scrollbar-container.cs-message-list__scroll-wrapper.ps {
    overscroll-behavior-y: none;
    overflow-anchor: auto;
    touch-action: none;
    max-height: 300px !important;
}



.input-with-original-styles  {
    width: 80%;
    height: 50px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    outline: none;
    font-size: 14.56px;
}

.input-with-original-styles:not([data-color-scheme='dark']) {
    background-color: #fff;
    color: #000000 !important;
    font-size: 16px;
    /* add any other styles you want to preserve */
}
