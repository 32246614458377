.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: transparent;
    color: #fff;
    text-align: center;
    padding: 1em 0;
    z-index: 10;
    font-size: 10px;
    display: flex;
    justify-content: center; 
    align-items: center; 
}

@media only screen and (max-width: 1000px) {
    .footer {
        padding: 1em 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}