:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  color-scheme: light dark;
  color: rgba(0, 0, 0, 0.87);
  background-color: black;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}

.cs-main-container {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: auto;
  border: solid 1px #d1dbe3;
  box-sizing: border-box;
  color: rgba(0, 0, 0, .87);
  background-color: #000000;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

h5{
  color: #f0ffff;
  margin-bottom: 5px;
  margin-top: 5px;
}

h3{
  color: #f9f9f9;
}
a {
  font-weight: 500;
  color: #ffffff;
  text-decoration: inherit;
}

a:hover {
  color: #535bf2;
}

body {
  margin: 0;
  display: flex;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
  background-color: #030303c2;
  overflow-y: scroll;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}

button:hover {
  border-color: #646cff;
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #000000;
  }

  a:hover {
    color: #747bff;
  }

  button {
    background-color: #f9f9f9;
  }
}

.ant-btn-primary {
  color: #fff;
  background-color: #333 !important ;
  box-shadow: 0 2px 0 rgb(5 145 255 / 10%);
  margin: 20px 0;
  z-index: 5;
}

.ant-btn-primary:disabled {
  border: none;
}